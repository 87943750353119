.map-wrap {
  position: relative;
  width: 100vw;
  height: calc(100vh - 130px); /* calculate height of the screen minus the heading */
}

.map-wrap-mobile {
  position: relative;
  width: 100vw;
  height: calc(100vh); /* calculate height of the screen minus the heading */
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.toast {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 3;
}

.toast-legend {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 3;
}

.bt-toast {
  position: relative;
  top: 10px;

  z-index: 3;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 4;
}

.watermark {
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 999;
}

.my-custom-control {
  color: #f8f8f8;
  background: #3887be;
  padding: 8px;
  border-radius: 4px;
  margin: 8px;
}

.popupCustom {
  padding: 0px;
}

.maplibregl-popup-content {
  padding: 4px;
}

.data-button {
  background-color: #3887be;
  color: #fff;
}

.checked {
  position: relative;
  color: #fff;
  border-color: #00aeff;
  background-color: #00aeff;
}

.checked:after {
  position: absolute;
  z-index: 2;
  top: -15px;
  right: -15px;
  padding: 5px;
  font-size: 70%;
  border-radius: 4px;
  background-color: tomato;
  content: 'Selected';
}

.mapboxgl-popup-content,
.maplibregl-popup-content {
  position: relative;
  border-radius: 0px;
  padding: 0px 0px 0px;
  pointer-events: auto;
  background-color: 'transparent';
}

.mapboxgl-popup-tip,
.maplibregl-popup-tip {
  width: 0;
  height: 0;
  border: 10px solid transparent;

  z-index: 1;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #00aeff;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #00aeff;
}
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #00aeff;
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #00aeff;
}

.overlay {
  position: fixed;
  top: 90;
  left: 0;
  width: 100%;
  height: calc(100vh - 130px);
  background-color: rgba(179, 229, 247, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Make sure the overlay is on top of everything */
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 5px solid #b3e5f7;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
