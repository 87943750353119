
.station-details table{
    text-align: left;
    left: 0px;
    font-size: 12px;
    background-color:beige;

}

.station-details span {
    font-size: small;
    font-weight: 800;
    color:#1c445f;
}

.data-details{
    text-align: left;
    left: 0px;
    font-size: medium;
    color: #1c445f;
    background-color:beige;

}

.station-details tr:nth-child(odd) {
    background-color: #dce5e9;
}

.station-details tr:nth-child(even) {
    background-color: #c2cacd;
}

.station-details th {
    background-color: #000000;
}

.station-local{
    text-align: center;
    font-size: 10px;
    background-color:rgb(193, 225, 237);
}

.station-local td{
    color: #777;
    font-weight: bolder;
}


.station-local tr:nth-child(odd) {
    color: rgb(47, 128, 209);
}

.station-local tr:nth-child(even) {
    color: rgb(146, 202, 253);
}

.bar-details {
    color: rgb(47, 128, 209);
    font-size: 0.9em;
    font-weight: 400;
    margin-top: -10px;
}

.d-button {
    margin: 0px;
    padding: 0px;
    border: none;
    background-color: transparent;
}