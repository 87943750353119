.sat {
    color: #fff;
    background-color: #82ca9d;
    font-size: 12px;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding-left: 5px;
}

.loc {
    color: #fff;
    background-color: #8884d8;
    font-size: 12px;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding-left: 5px;
}

.close {    
    display: flex;
    justify-content: center;
    align-content: center;  
}

h5 {
    color: #3887be;
}

